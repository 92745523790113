import React, { useState } from "react";
import { InputAdornment } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

import { Box, Button, Checkbox, colors, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { Redirect, useHistory, useLocation } from "react-router-dom";
//import SigninPage from "./components/SigninPage";
//import TitleBox from "./components/TitleBox";
import MainLayout from "./MainLayout";

import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import './Login.css';
import RecuperarPassword from "./RecuperarPassword/recuperarPassword";
import { validarlogin } from "../../servicios/api";
import { ToastContainer, toast } from 'react-toastify';




const Login: React.FC = () => {
  return (
    <>
    <ToastContainer toastStyle={{ color: "black" }} />
    <MainLayout>
      <Box
        sx={{
          width: {
            sm: "90vw",
            xs: "90vw",
            md: "68vw",
            lg: "64vw",
            xl: "60vw",
          },
        }}
      >
        {/* GRID SYSTEM */}
        <Grid container height="80vh"
          style={{
            borderRadius: "30px",
            boxShadow: `0px 0px 25px 10px rgba(0, 0, 0, 0.2)`
          }}>




          <InfoLogin />

          <FormularioLogin />
        </Grid>
        {/* GRID SYSTEM END */}
      </Box>
    </MainLayout>
    </>
  );
};

export default Login;

////////////////////////////////////////////////////////////
const FormularioLogin: React.FC = () => {

  const history = useHistory();
  const location = useLocation();

  const [txtuser, setTxtuser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [noUser, setNoUser] = useState<string>("");
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [loadingImage, setLoadingImage] = useState<Boolean>(false);
  const [avanzar, setAvanzar] = useState<Boolean>(false);
  const [rpassword, setRpassword] = useState<Boolean>(false);




  async function hacerlogin() {

    console.log("Adtoas ")
    console.log(txtuser)
    console.log(password)

    if (validateEmail(txtuser)) {
      // Aquí puedes implementar la lógica para enviar la solicitud de recuperación de contraseña
      console.log('Correo electrónico enviado:', txtuser);
     

    var resp = await validarlogin(txtuser, password);
    //console.log(JSON.stringify(resp));
    if (resp.code == 0) {
      history.push(location.pathname);
      setAvanzar(true)
    }
    else {
    
      toast.info("Contraseña incorrecta. Vuelve a intentarlo", { autoClose: 3500, position: toast.POSITION.TOP_LEFT });
    } 

    } else {
      toast.error("Correo inválido", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
     
    }

  }

  function validateEmail(email:any) {
    // Expresión regular para validar el correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  function verPassword() {
    console.log("Arias --")
    setRpassword(true)
  }


  const userChange = (e: any) => setTxtuser(e.target.value);
  const passwordChange = (e: any) => setPassword(e.target.value);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  }


  

  const revisarenter = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      //console.log("enter");
      hacerlogin();
    }
  }

  function cerrarmodal(dato:any)  {

   setRpassword(false);
    if (dato == 1) {

    }
}

  return (
    <>


      {(avanzar == true) ? (
        <Redirect from='/' to={"/home"} />
      ) : null}


      {(rpassword == true) ? (
        <RecuperarPassword show={rpassword}  cerrarmodal={cerrarmodal} />
      ) : null}


      <Grid

        xs={0} sm={0} md={6} lg={6} xl={6} minHeight={550} >
        <Box


          sx={{
            backgroundColor: "#5a49f8",
            padding: "20px",
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
              lg: 'flex',
              xl: 'flex'
            },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            borderRadius: "0px 30px 30px 0",

          }}
        >
          <Box width="62%">
            <Box display="flex" flexDirection="column" alignItems="center">




              <Typography color="white" fontWeight="bold" sx={{ textAlign: 'center', margin: 0 }} mt={7} mb={3}>
              </Typography>
              <Typography color="white" fontWeight="bold" sx={{ fontSize: "x-large", textAlign: 'center', marginTop: 4 }} mt={7} mb={3}>
                Inicio de Sesión
              </Typography>
            </Box>

            <div>
              <label className="lbltitulologin" htmlFor="username">Usuario:</label><br />

              <Input className="inputslogin" type={"text"}
                onChange={userChange}
                value={txtuser}
              />

            </div>
            <br />
            <div >
              <label className="lbltitulologin" htmlFor="password">Contraseña:</label><br />
              <Input className="inputslogin" type={showPassword ? "text" : "password"}
                onChange={passwordChange}
                value={password}
                onKeyDown={(e) => revisarenter(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                } />

              <br />
              {noUser ? <p className="puserincorrect">Usuario o contraseña incorrectos</p> : null}

            </div>

            {/* <CustomInput
         label="MFA Code"
         placeholder="Enter your code..."
         isIconActive={true}
       /> */}
            {/* INPUT END */}

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mt={2}
              width="100%"
              color="white"
            >
              <div style={{ display: "flex" }}>
                <Checkbox disableRipple sx={{ p: 0, pr: 1 }} />
                <Typography style={{ marginTop: "15px" }}>Recuerdame</Typography>
              </div>
              <a
              
                style={{
                  fontSize: "small",
                  marginTop: "8px",
                  color: "whitesmoke",
                  letterSpacing: "0.9px",
                  textAlign: "right"
                }}
                onClick={verPassword}
              >
                Recuperar contraseña?
              </a>
            </Box>
            <Button
              onClick={hacerlogin}
              variant="contained"
              fullWidth
              style={{
                marginTop: "25px",
                borderRadius: "30px",
                padding: "10px",
                backgroundColor: "#4137a4",
                color: "white"
              }}

            >
              Entrar
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

////////////////////////////////////////////////////////////
const InfoLogin: React.FC = () => {

  const bgImage = require("./../../assets/coin.png");

  return (

    <Grid
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={6}
      minHeight={550}
      sx={{
        boxShadow: {
          xs: "",
          sm: "",
          md: "15px 2px 5px -5px",
          lg: "15px 2px 5px -5px",
          xl: "15px 2px 5px -5px",
        },
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}>
      <Box
        sx={{

          backgroundImage: `linear-gradient(125deg, rgba(90,73,248,1) 25%, rgba(236,236,236,1) 100%)`,

          display: "flex",
          borderRadius: {
            xs: "30px",
            sm: "30px",
            md: "30px 0 0 30px",
            lg: "30px 0 0 30px",
            xl: "30px 0 0 30px",
          },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >

        <Box
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
            height: "190px",
            width: "190px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography variant="h4" fontWeight="bold" color="black" mb={3}>
            Bienvenido a Koin!<br />
          </Typography>
          <Typography variant="body1" fontWeight="bold" color="black" style={{ color: "black", letterSpacing: "0.9px", textAlign: "center" }}>
            Diseña tu futuro financiero con claridad<br />Crea metas, establece presupuestos
          </Typography>
        </Box>
        <div
          style={{
            width: "150px",
            height: "180px",
          }}
        >

        </div>
      </Box>
    </Grid>

  )

}


