import React from "react";
import MainHome from "../../pages/Home/MainHome";
import NavBar from "../navbar/navbar";



export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
                 <NavBar /> 
                   {this.props.ventana == "home" ?
                    <MainHome />
                    :
                    this.props.ventana == "manifiesto" ?
                    {/*<Manifiesto />*/}
                    :
                    null
                }
            </>
        )

    }
}