import React from "react";
import { GrFormSearch } from 'react-icons/gr';
import { MdRefresh } from 'react-icons/md';
import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import './MainHome.css';
import { ImKey } from "react-icons/im";
import { FaUserCircle } from "react-icons/fa";
import { Button } from "@mui/material";
import { RiUserAddFill } from "react-icons/ri";
import AgregarUsuarios from "../../componentes/Modals/AgregarUsuarios/AgregarUsuarios";
import { getUser, srtPassword } from "../../servicios/api";
import { HiOutlineKey } from "react-icons/hi";
import { ToastContainer, toast } from 'react-toastify';

export default class MainHome extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            txtusuario: "",
            txt_buscador: "",
            array: [
                {
                    "usuario": "Arias",
                    "estatus": false,
                    "cuentas": 10,
                    "activo": true,
                },
            ],
            array_filtro: [],
            respaldo_data: [],
            loanding: false,
            fechaselect: null,
            fechajson: '',
            ver_multipagos: false,
            ver_fecha: false,
            veruser: false,
            fecha: '',
        }

    }

    async componentDidMount() {

        this.setState({ loanding: true });
        var dia_ac = new Date(),
            month = '' + (dia_ac.getMonth() + 1),
            day = '' + dia_ac.getDate(),
            year = dia_ac.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }


        this.setState({ fechajson: [year, month, day].join('-') });
        this.setState({ fecha: [day, month, year].join('/') });




        var resp = await getUser()

        if (resp.code == 0) {
            this.setState({ array: resp.data })
        }

        setTimeout(async () => {

            this.setState({ loanding: false });

        }, 300);


    }


    save_input_locacion = async (e) => {

        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.apellido.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()))

            if (filtrofinal.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }

    cambiarPassword = async (item) => {

        this.setState({ loanding: true });
        console.log("Click ")

        let json = {
            "email": item.email
        }
        var resp = await srtPassword(json)
        if (resp.code == 0) {
            toast.success("Se envió un Correo para restablecer la contraseña", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });

        }
        setTimeout(async () => {

            this.setState({ loanding: false });

        }, 300);
    }


    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    selectitem = (index) => {
        console.log("Click " + index)
    }

    agregarUser = (index) => {
        console.log("Click " + index)
        this.setState({ veruser: true })


    }


    closemodal = (dato) => {
        this.setState({ veruser: !this.state.veruser })
        if (dato == 1) {

            this.componentDidMount();

        }
    }


    render() {

        return (
            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.veruser == true) ? (
                    <AgregarUsuarios show={this.state.veruser} infoModal={[]}
                        cerrarmodal={this.closemodal} />
                ) : null}



                <div className="contenedorhome  ">

                    <div className="row scroll">
                        <div className="col">

                            <br />
                            <br />
                            <label className="lbllistat letra">Usuarios</label>

                            <br />   <br />



                            <br />
                            <div className="row align-items-end">
                                <div className="col-auto ">
                                    <div className="input-group ">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                        </div>
                                        <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                            className="form-control" aria-label="Username" placeholder="Buscar Nombre" aria-describedby="basic-addon1"></input>
                                    </div>
                                </div>


                                <div className="col"></div>

                                <div className="col-auto">

                                    <Button className="bluebutton" onClick={this.agregarUser}><RiUserAddFill style={{ marginRight: "5px" }} />Agregar Usuario</Button>
                                </div>

                            </div>


                            <div className="shr "></div>
                            <br />
                            <br />

                            <div className="divhorizontal ">
                                <div style={{ minHeight: '430px' }} className='divscrollhome'>



                                    {this.state.txt_buscador.length == 0 ?
                                        <>

                                            <div className="row srows">

                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="txttitlestable">ID</label>
                                                </div>
                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="txttitlestable">Nombre</label>
                                                </div>
                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="txttitlestable">Apellido</label>
                                                </div>
                                                <div className="col-3 textocentro sinpadding">
                                                    <label className="txttitlestable">Correo</label>
                                                </div>
                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="txttitlestable">Estatus</label>
                                                </div>
                                                <div className="col textocentro sinpadding">

                                                </div>



                                            </div>



                                            {this.state.array.map((item, index) => (

                                                <>


                                                    <div key={index} className={item.activo == false ? "row bordertable grenn" : item.seleccionar == true || item.collage == true ? "row bordertable fondoselect" : "row bordertable"} onClick={this.selectitem.bind(this, index)}>
                                                        <div className="col-1 spaciostabla textocentro sinpadding ">
                                                            <label className="subtexttable">{(index + 1)}</label>
                                                        </div>

                                                        <div className="col-2 spaciostabla textocentro sinpadding ">
                                                            <label className="subtexttable">{item.nombre}</label>
                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.apellido}</label>
                                                        </div>
                                                        <div className="col-3 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.email}</label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.estatus}</label>

                                                        </div>


                                                        <div className="col spaciostabla textocentro sinpadding">


                                                            <Button className="greenbutton" onClick={this.cambiarPassword.bind(this, item)}><HiOutlineKey style={{ marginRight: "5px" }} />Rst Contraseña</Button>



                                                        </div>


                                                    </div>





                                                </>

                                            ))}

                                        </> :
                                        <>


                                            <div className="row srows">

                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="txttitlestable">ID</label>
                                                </div>
                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="txttitlestable">Nombre</label>
                                                </div>
                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="txttitlestable">Apellido</label>
                                                </div>
                                                <div className="col-3 textocentro sinpadding">
                                                    <label className="txttitlestable">Correo</label>
                                                </div>
                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="txttitlestable">Estatus</label>
                                                </div>
                                                <div className="col textocentro sinpadding">

                                                </div>



                                            </div>



                                            {this.state.array_filtro.map((item, index) => (

                                                <>


                                                    <div key={index} className={item.activo == false ? "row bordertable grenn" : item.seleccionar == true || item.collage == true ? "row bordertable fondoselect" : "row bordertable"} onClick={this.selectitem.bind(this, index)}>
                                                        <div className="col-1 spaciostabla textocentro sinpadding ">
                                                            <label className="subtexttable">{(index + 1)}</label>
                                                        </div>

                                                        <div className="col-2 spaciostabla textocentro sinpadding ">
                                                            <label className="subtexttable">{item.nombre}</label>
                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.apellido}</label>
                                                        </div>
                                                        <div className="col-3 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.email}</label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.estatus}</label>

                                                        </div>


                                                        <div className="col spaciostabla textocentro sinpadding">


                                                            <Button className="greenbutton" onClick={this.cambiarPassword.bind(this, item)}><HiOutlineKey style={{ marginRight: "5px" }} />Rst Contraseña</Button>



                                                        </div>


                                                    </div>





                                                </>

                                            ))}


                                        </>}

                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </>
        )
    }
}